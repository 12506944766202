/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    li: "li",
    a: "a",
    section: "section",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong",
    img: "img",
    canvas: "canvas",
    ul: "ul"
  }, _provideComponents(), props.components), {TableOfContents, Button} = _components;
  if (!Button) _missingMdxReference("Button", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement("section", {
    className: "uk-section uk-light uk-background-dark uk-padding-remove uk-position-relative uk-page-index-header"
  }, React.createElement("div", {
    className: "uk-container"
  }, React.createElement("div", {
    className: "uk-grid"
  }, React.createElement("div", {
    className: "uk-width-1-2 uk-width-1-4@s uk-margin-right"
  }, React.createElement("p", {
    className: "uk-text-15 uk-style-secondary uk-text-uppercase uk-text-fw-m uk-text-ls-increase"
  }, "Lead by"), React.createElement("span", {
    className: "uk-icon uk-preserve",
    "data-uk-icon": "icon: cambridge-university-logo"
  })), React.createElement("div", {
    className: "uk-width-expand"
  }, React.createElement("p", {
    className: "uk-text-15 uk-style-secondary uk-text-uppercase uk-text-fw-m uk-text-ls-increase"
  }, "In partnership\nwith"), React.createElement("span", {
    className: "uk-icon uk-preserve uk-margin-right",
    "data-uk-icon": "icon: tezos"
  }), React.createElement("span", {
    className: "uk-icon uk-preserve",
    "data-uk-icon": "icon: geotree"
  }))))), "\n", React.createElement(TableOfContents, null, React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#what-is-a-pact-"
  }, "What is a PACT?")), React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#how-can-you-use-pact-"
  }, "How can you use PACT?"))), React.createElement(_components.section, {
    className: "uk-section uk-position-relative  uk-background-dark uk-light uk-position-z-index  ",
    id: "what-is-a-pact-"
  }, React.createElement(_components.div, {
    className: "uk-container uk-container-medium uk-position-offset-right uk-position-relative uk-position-z-index"
  }, React.createElement(_components.div, {
    className: "uk-grid"
  }, React.createElement(_components.div, {
    className: "uk-width-expand@m"
  }, React.createElement(_components.p, {
    className: "uk-style-meta uk-color-tertiary uk-text-meta-central uk-text-13 uk-scrollspy-inview "
  }, "What is a PACT?"), React.createElement(_components.h2, null, "Permanent Additional Carbon Tonnes"), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Calculating the value of natural carbon storage equivalent to a geologically stored (permanent) tonne."))), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "PACT offers a ", React.createElement(_components.strong, null, "direct comparison"), " between different types of carbon credits with a new framework to demonstrate the long-term carbon value and additionality of nature-based solutions like forest preservation and reforestation.")), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "The climate benefit behind ", React.createElement(_components.strong, null, "a single PACT is equivalent to a tonne of CO₂"), " emitted into the atmosphere, accounting for the impermanence of nature-based carbon storage, and with a provable ‘additional’ impact on carbon emissions.")), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Backed by Cambridge University research"), ", conservation scientists have developed a new understanding of the true carbon storage value of natural environments like forests; computer scientists bring automation and scale to verification using remote sensing and machine learning.")), React.createElement(Button, {
    url: "/what-is-pact/",
    style: "primary"
  }, "Find out more about our approach"))))), "\n", React.createElement(_components.section, {
    className: "uk-section uk-position-relative uk-section-image-content uk-background-light uk-overflow-hidden  ",
    id: "how-can-you-use-pact-"
  }, React.createElement(_components.div, {
    className: "uk-position-two-column-text-circle-1"
  }), React.createElement(_components.div, {
    className: "uk-position-two-column-text-circle-2"
  }), React.createElement(_components.div, {
    className: "uk-position-two-column-text-circle-3"
  }), React.createElement(_components.div, {
    className: "uk-container uk-container-medium uk-position-offset-right uk-position-relative uk-position-z-index"
  }, React.createElement(_components.div, {
    className: "uk-grid"
  }, React.createElement(_components.div, {
    className: "uk-width-expand@m"
  }, React.createElement(_components.p, {
    className: "uk-style-meta uk-color-quinary uk-text-meta-right uk-text-13 uk-scrollspy-inview "
  }, "How can you use PACT?"), React.createElement(_components.div, {
    className: "uk-width-image-column  uk-image-float-left uk-margin-large-bottom@m uk-margin-medium-bottom"
  }, React.createElement(_components.div, {
    className: "uk-background-primary uk-overflow-hidden uk-position-relative  uk-border-rounded"
  }, React.createElement(_components.div, {
    className: "uk-width-1-1 uk-height-1-1 uk-background-sixth uk-position-absolute"
  }), React.createElement(_components.div, {
    className: "uk-cover-container"
  }, React.createElement(_components.img, {
    src: "/assets/images/pact-for-creators/image-content-1.jpg",
    alt: "A verdant valley with trees growing on the slopes",
    className: " uk-utility-object-fit-cover"
  }), React.createElement(_components.canvas, {
    width: "450",
    height: "600"
  })))), React.createElement(_components.h2, null, "For natural project developers"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Quicker, simpler verification process for land-holders selling nature-based carbon credits – ", React.createElement(_components.strong, null, "weeks instead of years")), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Fair and accurate assessment"), " of impermanent sequestration – compare forestry directly with geosequestration and other permanent storage"), "\n", React.createElement(_components.li, null, "Evidence-based assessment of the ‘additionality’, or the true climate impact of each project"), "\n", React.createElement(_components.li, null, "Measure ", React.createElement(_components.strong, null, "biodiversity"), " and other environmental outcomes as well as carbon storage"), "\n"), React.createElement(_components.div, {
    className: "uk-width-image-column  uk-image-float-right uk-margin-large-bottom@m uk-margin-medium-bottom"
  }, React.createElement(_components.div, {
    className: "uk-background-primary uk-overflow-hidden uk-position-relative  uk-border-rounded"
  }, React.createElement(_components.div, {
    className: "uk-width-1-1 uk-height-1-1 uk-background-sixth uk-position-absolute"
  }), React.createElement(_components.div, {
    className: "uk-cover-container"
  }, React.createElement(_components.img, {
    src: "/assets/images/innovation/image-content-2.jpg",
    alt: "A field of linked cubes representing the Tezos blockchain",
    className: " uk-utility-object-fit-cover"
  }), React.createElement(_components.canvas, {
    width: "450",
    height: "600"
  })))), React.createElement(_components.h2, null, "For carbon market partners"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use Cambridge-backed, ", React.createElement(_components.strong, null, "peer-reviewed methodology"), " to verify natural carbon storage"), "\n", React.createElement(_components.li, null, "Reach scale with ", React.createElement(_components.strong, null, "automated verification of credits"), " based on remote sensing and machine learning"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Fast, accurate, digital MRV"), " – measurement, reporting, verification – proves the real impact of nature-based solutions"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Transparent retirement tracking"), " on a trusted blockchain ledger"), "\n"), React.createElement(_components.div, {
    className: "uk-width-image-column  uk-image-float-left uk-margin-large-bottom@m uk-margin-medium-bottom"
  }, React.createElement(_components.div, {
    className: "uk-background-primary uk-overflow-hidden uk-position-relative  uk-border-rounded"
  }, React.createElement(_components.div, {
    className: "uk-width-1-1 uk-height-1-1 uk-background-sixth uk-position-absolute"
  }), React.createElement(_components.div, {
    className: "uk-cover-container"
  }, React.createElement(_components.img, {
    src: "/assets/images/quantify-earth/image-content-1.jpg",
    alt: "A false-colour satellite image",
    className: " uk-utility-object-fit-cover"
  }), React.createElement(_components.canvas, {
    width: "450",
    height: "600"
  })))), React.createElement(_components.h2, null, "For carbon credit buyers"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Reduce risk"), " with high quality, accurate assessment of nature-based carbon credit assessments"), "\n", React.createElement(_components.li, null, "Mitigate ", React.createElement(_components.strong, null, "greenwashing risk"), " with provable ‘additionality’ and project impact"), "\n", React.createElement(_components.li, null, "Transparent credit retirement: see your credits on a map backed by a ", React.createElement(_components.strong, null, "secure blockchain entry")), "\n", React.createElement(_components.li, null, "Directly support ", React.createElement(_components.strong, null, "sustainable, long-term natural projects"), " with true biodiversity and healthy communities"), "\n", React.createElement(_components.li, null, "Compare different types of carbon storage projects and ", React.createElement(_components.strong, null, "directly compare permanent and impermanent"), " sequestration"), "\n"))))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
